import ChangeDetail from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_change";
import buyer_dom_white_change from '@/lib/data-service/flight/buyer_dom_white_change'
import ChangRefund from "@/page/distributor/air-ticket/components/changRefund/1.0.2/changRefund.vue";
import { awaitWrap } from "@/page/distributor/train/front/common/unit";
import upLoadImg from "@/lib/data-service/default/web_common_upload_aptitudeSingleImage";
import ChangClause from "@/page/distributor/air-ticket/components/changClause";
import rule from "@/lib/data-service/flight/newFligthApi/buyer_dom_white_rule";
import ChildTicket from "@/page/distributor/air-ticket/components/childTicket";
// import buyer_dom_manage_changeQuery from '@/lib/data-service/flight/buyer_dom_manage_changeQuery'
import InlandFlightList from "@/page/distributor/air-ticket/components/inlandFlightList/1.0.2/index.vue";
import luggage from "@/lib/data-service/flight/buyer_dom_white_luggage";
import buyer_dom_manage_newApi_changeQuery from '@/lib/data-service/flight/buyer_dom_manage_newApi_changeQuery'
import buyer_dom_manage_changeReasonEnum from '@/lib/data-service/flight/buyer_dom_manage_changeReasonEnum'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import moment from "moment";
export default {
    data() {
        return {
            loading: false,
            detailData: {},
            orderNo: "",

            flightType: 1,

            allSegmentList: [], // 两层数组
            segmentList: [], // 两层数组筛选出来的结果
            changeSegment: [], // 最终对比后显示的结果
            flight: {},

            formData: {
                changeType: 1,
                orderNo: '',
                passengerNoList: [],
                remark: "",
                segmentList: [],
                chaReason: 1,
                changeReasonCode: '',
            },


            beginDate: "",
            pickerOptions: {
                disabledDate: this.setDisabledDate
            },
            defaultText: "-----",
            ruleText: "",
            options: [
                {
                    value: 1,
                    label: "自愿改签"
                },
                {
                    value: 2,
                    label: "非自愿改签"
                }
            ],
            changeReasonOption: [], // 改签原因枚举
            needUploadProof: false,
            changeRangeDateBegin: '',
            changeRangeDateEnd: '',

            disabledTime: null,
            fileImg: [],
            dialogLoading: false,
            dialogChangeVisible: false,
            flightStartSort: true,
            flightEndSort: true,
            changeParams: {},
            changingIndex: null,
            flightsList: [],
            luggage: '',
            changeRuleText: [],
            psgType: 1, //  乘机人类型
            creditState: false,
            reserveType: '确定', // 按钮状态

            ossClient: new OssClient(),
            hasChd: false
        };
    },
    components: {
        ChangRefund,
        ChangClause,
        ChildTicket,
        InlandFlightList
    },
    filters: {
        filterTime(val) {
            let time = moment(val, 'HH:mm:ss').format('HH:mm');
            return time
        },
        filterDayAndTime(val) {
            let time = moment(val).format('YYYY-MM-DD HH:mm');
            return time
        }
    },
    watch: {
        formData: {
            handler(newVal, oldVal) {
                //console.log('n', newVal, oldVal)
            },
            deep: true
        }
    },
    methods: {
        setDisabledDate(time) {
            // 是否今天之前
            const beforeToday = time.getTime() < Date.now() - 1000 * 3600 * 24;
            // 是否有时间范围
            let rangeDateOut = false;
            if (this.changeRangeDateBegin) {
                const beginDate = moment(this.changeRangeDateBegin + ' 00:00:00').valueOf();
                const endDate = moment(this.changeRangeDateEnd + ' 23:59:59').valueOf();
                if (time.getTime() >= beginDate && time.getTime() <= endDate) {
                    rangeDateOut = false
                }  else {
                    rangeDateOut = true
                }
            }
            return beforeToday || rangeDateOut
        },
        async getDetailData() {
            let _this = this;
            this.loading = true
            let [err, res] = await awaitWrap(ChangeDetail({ orderNo: this.orderNo }))
            if (err) {
                this.loading = false;
                this.$alert(err.data.msg, '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.$router.go(-1);
                    }
                });
                return
            }
            this.loading = false;
            res.changeRes.passengerList.map(val => {
                val.changeSegmentList.map(item => {
                    item.oriFlightNo = item.flightNo
                    item.oriShareFlightNo = item.shareFlightNo
                    item.oriAirlineName = item.airlineName
                    item.oriShareAirlineName = item.shareAirlineName
                    item.oriBeginDate = item.beginDate
                    item.oriStartDate = item.beginDate + " " + item.beginTime
                    item.oriBeginDate = item.beginDate;
                    item.oriBeginTime = item.beginTime;
                    item.startTime = item.beginDate + " " + item.beginTime
                    item.endTime = item.arriveDate + " " + item.arriveTime;
                    item.psgType = res.changeRes.psgType
                    item.cardType = res.changeRes.cardType
                    item.psgNo = val.psgNo
                    item.cabinName = item.cabinRankName
                    if (res.changeRes.passengerList !== null){
                        res.changeRes.passengerList.forEach(p => {
                            console.log(_this.hasChd)
                            console.log(p.psgType)
                            if (!_this.hasChd && p.psgType === 2){
                                _this.hasChd = true;
                            }
                        });
                    }
                });
            });
            this.detailData = res.changeRes;
            this.flightType = this.detailData.flightType || 1;

            let [optionErr, optionRes] = await awaitWrap(buyer_dom_manage_changeReasonEnum({orderNo: this.orderNo}));
            if (optionErr) {
                return
            }
            this.changeReasonOption = optionRes.changeReasonList;
            for (let i = 0, l = this.changeReasonOption.length; i < l; i++) {
                const item = this.changeReasonOption[i];
                if (item.chaReason === 1) {
                    this.formData.changeReasonCode = item.changeType;
                    this.needUploadProof = item.needUploadProof;
                    break
                }
            }
            let passengerList = this.detailData.passengerList ? this.detailData.passengerList[0] : {};
            const changeSearchParams = {
                changeReasonCode: this.formData.changeReasonCode,
                changeType: this.formData.changeType,
                depDate: passengerList.changeSegmentList ? passengerList.changeSegmentList[0].oriBeginDate : '',
                flightNo: passengerList.changeSegmentList ? passengerList.changeSegmentList[0].oriFlightNo : '',
                orderNo: this.orderNo,
                psgNoList: [],
            };
            buyer_dom_manage_newApi_changeQuery(changeSearchParams).then(result => {
                this.changeRangeDateBegin = result.changeRangeDateBegin;
                this.changeRangeDateEnd = result.changeRangeDateEnd;
                this.setStartFlight();
            })
        },
        setStartFlight() {
            if (this.changeRangeDateBegin && this.changeSegment.length > 0) {
                //console.log('changeSegment', this.changeSegment)
                this.changeSegment.forEach(value => {
                    // 如果是在时间范围内且大于等于今天
                    const activeBeginDate = moment(value.beginDate + ' 00:00:00').valueOf();
                    // 是否今天之前
                    const beforeToday = activeBeginDate < Date.now() - 1000 * 3600 * 24;
                    let rangeDateOut = false;
                    const beginDate = moment(this.changeRangeDateBegin + ' 00:00:00').valueOf();
                    const endDate = moment(this.changeRangeDateEnd + ' 23:59:59').valueOf();
                    if (activeBeginDate >= beginDate && activeBeginDate <= endDate) {
                        rangeDateOut = false
                    }  else {
                        rangeDateOut = true
                    }

                    // 如果不通过
                    if (beforeToday || rangeDateOut) {
                        // 获取时间范围内的大于等于今天的第一天
                        let canUseDate = -1;
                        let now = ''
                        const changeRangeDateBegin = moment(this.changeRangeDateBegin + ' 00:00:00');
                        const changeRangeDateEnd = moment(this.changeRangeDateEnd + ' 23:59:59');
                        const daysRemaining = changeRangeDateEnd.diff(changeRangeDateBegin, 'days');
                        //console.log('daysRemaining', daysRemaining)
                        for (let i = 0, l = daysRemaining + 1; i < l; i++) {
                            now = changeRangeDateBegin.valueOf() + (24 * 60 * 60 * 1000 * i);
                            const activeTimeBeforeToday = now < Date.now() - 1000 * 3600 * 24;
                            //console.log(moment(now).format('YYYY-MM-DD'), activeTimeBeforeToday)
                            if (!activeTimeBeforeToday) {
                                canUseDate = i;
                                break
                            }
                        }
                        if (canUseDate === -1) {
                            // 没有可以匹配的情况选第一天
                            value.beginDate = this.changeRangeDateBegin;
                            value.startTime = `${value.beginDate} ${value.beginTime}`
                        } else {
                            value.beginDate = moment(now).format('YYYY-MM-DD');
                            value.startTime = `${value.beginDate} ${value.beginTime}`
                        }
                    }
                })
            }
        },
        submit() {
            let data = JSON.parse(JSON.stringify(this.formData));
            // 判断是否需要检验附件
            if (this.needUploadProof && (!this.formData.url || this.formData.url === '')) {
                return this.$message({ type: "warning", message: "请上传延误/病退证明", offset: 80 });
            }
            // 判断是否必填备注
            if ((this.formData.changeReasonCode === '600' || this.formData.changeReasonCode === '700') && this.formData.remark === '') {
                return this.$message({ type: "warning", message: "请填写备注", offset: 80 });
            }
            // 过滤改签人null
            let passengerNoList = []
            data.passengerNoList.forEach(value => {
                if (value) {
                    passengerNoList.push(value)
                }
            })
            data.passengerNoList = passengerNoList
            if (!data.segmentList.length)
                return this.$message({ type: "warning", message: "请选择改签航班", offset: 80 });

            // 改签的航班号、舱位、起飞时间与原航班一致时提示请选择改签航班
            let isHasSameFlight = false
            data.segmentList.forEach(value => {
                this.segmentList.forEach(value1 => {
                    if (value1.length > 0) {
                        value1.forEach(value2 => {
                            if (value.startTime === value2.startTime && value.cabinCode ===  value2.cabinCode && value.flightNo === value2.flightNo) {
                                isHasSameFlight = true
                            }
                        })
                    }

                })
            })
            if (isHasSameFlight) {
                return this.$message({ type: "warning", message: "请选择改签航班", offset: 80 });
            }
            let _this = this;
            if (this.psgType === 1) {
                data.segmentList = this.getSegmentList(data.segmentList);
                if (data.chaReason === 2) {}
            } else if (this.psgType === 2) {
                data.segmentList = this.getSegmentList(data.segmentList);
                data.adultTicketNo = data.segmentList.map(item => {
                    return item.aduTicketNumber;
                });
                data.adultTicketNo = data.adultTicketNo.join(",");
                if (data.chaReason === 2) {}
            } else {
                if (!data.remark)
                    return this.$message({ type: "warning", message: "请输入备注！", offset: 80 });
                for (let index = 0; index < data.segmentList.length; index++) {
                    if (!data.segmentList[index].aduTicketNumber)
                        return this.$message({
                            type: "warning",
                            message: "请输入成人票号！",
                            offset: 80
                        });
                }
                let list = data.segmentList.map(sItem => {
                    let time = this.$moment(sItem.startTime).format("YYYY-MM-DD HH:mm:ss").split(" ");
                    let Obj = {
                        cabinCode: sItem.cabinCode,
                        depDate: time[0],
                        depTime: time[1],
                        arrDate: sItem.arriveDate,
                        arrTime: sItem.arriveTime,
                        flightNo: sItem.flightNo,
                        segmentNo: sItem.segmentNo,
                        price: sItem.price,
                        newArrAirportCode: sItem.newArrAirportCode,
                        newArrAirportName: sItem.newArrAirportName,
                        newDepAirportCode: sItem.newDepAirportCode,
                        newDepAirportName: sItem.newDepAirportName,
                        cabinRank: sItem.cabinRank,
                    };
                    return Obj;
                });
                data.adultTicketNo = data.segmentList.map(item => {
                    return item.aduTicketNumber;
                });
                data.adultTicketNo = data.adultTicketNo.join(",");
                data.segmentList = list;
            }
            this.loading = true;
            //console.log(data)
            buyer_dom_white_change(data).then(res => {
                this.loading = false;
                if (res.code === this.SUCCESS_CODE) {
                    // 判断如果是轻松付待还款
                    if (res.needPayAbleAmount === 1) {
                        this.$router.push({
                            name: "air-ticket-admin-qingsongfu-payment",
                            query: {
                                bizOrderNo: res.chaOrderNo,
                                orderNo: this.orderNo,
                                //passengerNoList: data.passengerNoList
                            }
                        });
                    } else {
                        this.$router.push({
                            name: "air-ticket-admin-change-order-list",
                        });
                        this.$message({ type: "success", message: "提交成功！", offset: 80 });
                    }
                }
            }, error => {
                this.loading = false;
                let errorCode = error.data.code;
                let errorText = error.data.msg;
                this.$alert(errorText, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //this.$router.go(-1)
                    }
                });
            }).catch(error => {
                this.loading = false;
                let errorCode = error.data.code;
                let errorText = error.data.msg;
                this.$alert(errorText, '温馨提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        //this.$router.go(-1)
                    }
                });
            });
        },
        getSegmentList(val) {
            const _this = this
            let segmentList = val.map(item => {
                let date = _this.$moment(item.startTime).format("YYYY-MM-DD HH:mm:ss");
                date = date.split(" ");
                let endDate = _this.$moment(item.endTime).format("YYYY-MM-DD HH:mm:ss");
                endDate = endDate.split(" ");
                let list = {
                    cabinCode: item.cabinCode,
                    depDate: date[0],
                    depTime: date[1],
                    arrDate: endDate[0],
                    arrTime: endDate[1],
                    flightNo: item.flightNo,
                    segmentNo: item.segmentNo,
                    newArrAirportCode: item.newArrAirportCode,
                    newArrAirportName: item.newArrAirportName,
                    newDepAirportCode: item.newDepAirportCode,
                    newDepAirportName: item.newDepAirportName,
                    cabinRank: item.cabinRank,
                };
                return list;
            });
            return segmentList;
        },

        checkSelectable(val) {
            return true;
        },
        uploadImg(file) {
            this.ossClient.multipartUpload({
                file: file.file,
            }).then((res) => {
                this.fileImg.push({ url: res.url, uid: file.file.uid });
                //console.log(res.url);
                this.getUrl(this.fileImg);
            }).catch(() => {
            }).finally(() => {
            });
        },
        getChildAirInfo(data) {
            let passengerNoList = this.formData.passengerNoList;

            let segmenList = {
                cabinCode: data.val.policys[0].cabin.chdCabinCode,
                depDate: data.val.depDate,
                depTime: data.val.depTime,
                flightNo: data.val.flightNo,
                segmentNo: data.changAir.segmentNo,
                arrDate: data.val.arrDate,
                arrTime: data.val.arrTime
            };
            if (this.formData.passengerNoList.length == 0) {
                passengerNoList.push(data.air.psgNo);
            } else {
                if (!data.btnShow) {
                    passengerNoList.push(data.air.psgNo);
                } else {
                    this.formData.passengerNoList.map((item, index) => {
                        if (item == data.air.psgNo) {
                            this.formData.passengerNoList.splice(index, 1);
                        }
                    });
                    this.formData.segmentList.map((segment, index) => {
                        if (segment.segmentNo == data.changAir.segmentNo) {
                            this.formData.segmentList.splice(index, 1);
                        }
                    });
                    return;
                }
            }

            this.formData.segmentList.push(segmenList);
        },
        backRule(val) {
            this.ruleText = "";
            let data = {
                arrCityPortCode: val.arrAirportCode,
                cabinCode: this.psgType === 2 ? val.policys[0].cabin.chdCabinCode : val.policys[0].cabin.cabinCode,
                depCityPortCode: val.depAirportCode,
                depDate: val.depDate,
                flightNo: val.flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: val.supplierType,
                policyId: val.policys[0].policyId,
                extData: val.policys[0].extData,
            };
            rule(data).then(res => {
                this.ruleText = res.chaRule.content;
            });
        },
        backLuggage(val, policys) {
            this.luggage = ''
            this.ruleObj = val;
            let data = {
                arrCityPortCode: val.arrAirportCode,
                cabinCode: this.psgType === 2 ? policys.cabin.chdCabinCode : policys.cabin.cabinCode,
                depCityPortCode: val.depAirportCode,
                depDate: val.depDate,
                flightNo: val.flightNo,
                flightType: this.airType,
                psgType: this.psgType,
                supplierType: policys.supplierType || 1,
                policyId: policys.policyId,
                extData: policys.extData,
                businessExt: policys.businessExt,
            };
            this.getLuggage(data);
        },
        getLuggage(data) {
            luggage(data).then(res => {
                this.luggage = res.luggage;
            });
        },
        getUrl(val) {
            let url = val.map(item => {
                return item.url;
            });
            this.formData.url = url.join(",");
        },
        detectionImg(file) {
            const reg2 = /^(\s|\S)+(jpg|png|bmp)+$/;
            const isLt2M = file.size / 1024 < 3 * 1024;
            if (!reg2.test(file.name)) {
                this.$message({ type: "warning", message: "图片格式上传错误！" });
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 3M!");
                return false;
            }
        },
        removeImg(file) {
            this.fileImg.map((item, index) => {
                item.uid === file.uid ? this.fileImg.splice(index, 1) : "";
            });
            this.getUrl(this.fileImg);
        },
        selectType(val) {
            this.disabledTime = val;
            this.$forceUpdate();
        },

        selectFlight(val, name, flight) {
            //console.log('select', val, name, flight)
            let segmentItem = JSON.parse(JSON.stringify(val))
            this.allSegmentList[name] = segmentItem

            this.segmentList = []
            this.allSegmentList.forEach(value => {
                value.forEach(value1 => {
                    let isHasSameSegment = false
                    this.segmentList.forEach(value2 => {
                        if (value2.segmentType === value1.segmentType) {
                            value2.psgList.push(value1.psgNo)
                            isHasSameSegment = true
                        }
                    })
                    if (!isHasSameSegment) {
                        value1.psgList = [value1.psgNo]
                        this.segmentList.push(value1)
                    }
                })
            })
            // 增加没有的项
            this.segmentList.forEach(value => {
                let isHasItem = false
                this.changeSegment.forEach(value1 => {
                    if (value1.segmentType === value.segmentType) {
                        isHasItem = true
                    }
                })
                if (!isHasItem) {
                    this.changeSegment.push(value)
                }
            })
            // 去掉多余的项
            this.changeSegment.forEach((value, index) => {
                let isHasItem = false;
                this.segmentList.forEach(value1 => {
                    if (value1.segmentType === value.segmentType) {
                        isHasItem = true
                    }
                });
                if (!isHasItem) {
                    this.changeSegment.splice(index, 1);
                }
            });

            this.formData.segmentList = this.changeSegment;
            this.selectPassenger(val, name, flight);
            this.setStartFlight();
        },
        selectPassenger(val, name, flight) {
            val.length > 0 ? (this.flight[name] = flight) : (this.flight[name] = []);
            let list = Object.values(this.flight);
            let psgNo = list.map(item => {
                return item.psgNo;
            });
            this.formData.passengerNoList = psgNo;
        },
        // 改签选择航班
        flightTimeSort(type) {
            if (this.flightsList.length === 0) return;
            if (type === "start") {
                this.flightStartSort = !this.flightStartSort;
                this.flightEndSort = !this.flightEndSort;
                // depTime
                this.timeSort(this.flightsList, "depTime", this.flightStartSort);
            } else {
                this.flightEndSort = !this.flightEndSort;
                this.flightStartSort = !this.flightStartSort;
                // arrTime
                this.timeSort(this.flightsList, "arrTime", this.flightEndSort);
            }
        },
        timeSort(list, name, arrangeType) {
            if (arrangeType) {
                list.sort((a, b) => {
                    let one = moment(this.changeParams).format("YYYY-MM-DD") + " " + a[name];
                    let two = moment(this.time1).format("YYYY-MM-DD") + " " + b[name];
                    return moment(one).valueOf() - moment(two).valueOf();
                });
            } else {
                list.sort((a, b) => {
                    let one = moment(this.changeParams.depDate).format("YYYY-MM-DD") + " " + a[name];
                    let two = moment(this.changeParams.depDate).format("YYYY-MM-DD") + " " + b[name];
                    return moment(two).valueOf() - moment(one).valueOf();
                });
            }
        },
        clickDate(val) {
            this.$set(this.changeParams, 'depDate', val)
            this.searchChangeFlight();
        },
        // 选择航班
        changeFlight(val, index) {
            //console.log('change', val, this.formData)
            if (!val.beginDate || val.beginDate === '') {
                this.$message({
                    type: "warning",
                    message: '请先选择起飞时间'
                })
                return
            }
            let copyShareAirlineCode = val.shareAirlineCode
            let copyFlightNo = val.flightNo
            this.changingIndex = index
            let params = {
                flightType: 1,// 航程类型
                segmentTypeText: val.segmentTypeText,
                needPnr: 0,
                depCityPortName: val.sCityName,
                depCityPortCode: val.sAirportCode,// 出发城市三字码。
                arrCityPortName: val.eCityName,
                arrCityPortCode: val.eAirportCode,// 到达城市三字码。
                cabinRank: 0,// 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
                airLineName: val.airlineName, // oriAirlineName
                airLineCode: copyShareAirlineCode ? copyShareAirlineCode.slice(0, 2) : copyFlightNo.slice(0, 2),// 航空公司二字码
                psgType: val.psgType,// psgType
                directFlight: 0,//  是否直飞
                discount: 0,// 折扣
                takeOffInterval: '',
                supplierType: this.detailData.supplierType,

                changeType: this.formData.chaReason,
                depDate: val.beginDate,// 出发日期,
                flightNo: val.oriShareFlightNo ? val.oriShareFlightNo : val.oriFlightNo,
                orderNo: this.detailData.orderNo,
                psgNoList: val.psgList,
                changeReasonCode: this.formData.changeReasonCode,
            };
            this.changeParams = Object.assign({}, params)
            this.dialogChangeVisible = true
            //this.saveWeek = val.beginDate
            this.searchChangeFlight()
        },
        searchChangeFlight() {
            if (!this.changeParams.depDate) {
                this.$message({
                    message: '请先选择日期',
                    type: 'warning'
                });
                return
            }
            // this.saveWeek = this.changeParams.depDate
            this.dialogLoading = true
            buyer_dom_manage_newApi_changeQuery(this.changeParams).then(data => {
                this.changeRangeDateBegin = data.changeRangeDateBegin;
                this.changeRangeDateEnd = data.changeRangeDateEnd;
                if (data.flights) {
                    this.dialogLoading = false;
                    let flightsList = data.flights
                    flightsList.forEach((value, index) => {
                        // let rebatePrice = 0
                        // let settlementPriceTaxes = 0
                        // value.rebatePrice = rebatePrice
                        // value.settlementPriceTaxes = settlementPriceTaxes
                    })
                    //console.log(flightsList)
                    this.flightsList = flightsList;
                    this.creditState = data.creditState;
                    this.airTypeShow = true;
                } else {
                    this.dialogLoading = false;
                }
            }, error => {
                this.flightsList = []
                this.dialogLoading = false;
            }).catch(err => {
                this.dialogLoading = false;
            });
        },
        // 行程选择
        journeyType(data, policy) {
            let changeSegmentItem = this.changeSegment[this.changingIndex]
            changeSegmentItem.flightNo = data.hasShare ? data.realFlightNo : data.flightNo
            changeSegmentItem.cabinCode = policy.cabinCode
            changeSegmentItem.oriStartTime = `${data.depDate} ${data.depTime}`
            changeSegmentItem.startTime = `${data.depDate} ${data.depTime}`
            changeSegmentItem.endTime = `${data.arrDate} ${data.arrTime}`
            changeSegmentItem.beginDate = data.depDate
            changeSegmentItem.beginTime = data.depTime
            changeSegmentItem.price = ''
            changeSegmentItem.airlineName = data.hasShare ? data.shareAirCompanyName : data.airCompanyName

            changeSegmentItem.newArrAirportCode = data.arrAirportCode
            changeSegmentItem.newArrAirportName = data.arrAirportName
            changeSegmentItem.newDepAirportCode = data.depAirportCode
            changeSegmentItem.newDepAirportName = data.depAirportName
            changeSegmentItem.cabinName = policy.cabinName
            changeSegmentItem.cabinRank = policy.cabinRank
            changeSegmentItem.newDepAirportName = data.depAirportName
            this.changeSegment[this.changingIndex] = changeSegmentItem
            this.dialogChangeVisible = false
            this.$message({
                message: '选择成功',
                type: 'success'
            });
        },

        clickTimeChangeFlight(item, index, value) {
            // 没选或者是往返单的时候，就不用主动弹出搜索框
            if (value === null || this.flightType === 2) {
                return
            }
            this.changeFlight(item, index)
        },
        closeChangeDialog() {
            this.dialogChangeVisible = false
            let changeSegmentItem = this.changeSegment[this.changingIndex]
            changeSegmentItem.startTime = changeSegmentItem.oriStartDate
            this.changeSegment[this.changingIndex] = changeSegmentItem
        },
        reactFormData() {
            this.formData = {
                changeType: 1,
                orderNo: this.orderNo,
                passengerNoList: [],
                remark: "",
                segmentList: [],
                chaReason: 1,
                changeReasonCode: '',
            }
        },
        changeChangeType(val) {
            //console.log('666', val)
            this.formData.changeReasonCode = '';
            for (let i = 0, l = this.changeReasonOption.length; i < l; i++) {
                if (this.changeReasonOption[i].chaReason === val) {
                    this.formData.changeReasonCode = this.changeReasonOption[i].changeType;
                    this.changeChangeReason(this.changeReasonOption[i].changeType)
                    break
                }
            }
        },
        changeChangeReason(val) {
            //console.log('777', val)
            //console.log(this.detailData.passengerList)
            const passengerList = this.detailData.passengerList[0];
            const changeSearchParams = {
                changeReasonCode: this.formData.changeReasonCode,
                changeType: this.formData.changeType,
                depDate: passengerList.changeSegmentList ? passengerList.changeSegmentList[0].oriBeginDate : '',
                flightNo: passengerList.changeSegmentList ? passengerList.changeSegmentList[0].oriFlightNo : '',
                orderNo: this.orderNo,
                psgNoList: [],
            };
            buyer_dom_manage_newApi_changeQuery(changeSearchParams).then(result => {
                this.changeRangeDateBegin = result.changeRangeDateBegin;
                //this.changeRangeDateBegin = '2022-11-07'
                this.changeRangeDateEnd = result.changeRangeDateEnd;
                //this.changeRangeDateEnd = '2022-12-07'
                this.setStartFlight();
            });
            this.changeReasonOption.forEach(value => {
                if (val === value.changeType) {
                    this.needUploadProof = value.needUploadProof
                }
            })
        },

        clickChangeStartTime(item, index, value) {
            if (value) {
                const timeArr = value.split(' ');
                item.beginDate = timeArr[0];
                item.beginTime = timeArr[1]
            } else {
                item.beginDate = '';
                item.beginTime = ''
            }

        },
        clickChangeEndTime(item, index, value) {
            console.log(item)
            if (value) {
                const timeArr = value.split(' ');
                item.arriveDate = timeArr[0];
                item.arriveTime = timeArr[1]
            } else {
                item.arriveDate = '';
                item.arriveTime = ''
            }
        },
    },
    created() {
    },
    async mounted() {
        this.orderNo = this.$route.query.ordersNo;
        this.psgType = parseInt(this.$route.query.psgType)
        this.reactFormData()
        await this.getDetailData();
    },
    // async activated() {
    //     this.orderNo = this.$route.query.ordersNo;
    //     this.reactFormData()
    //     await this.GetdetailData();
    // }
};
