//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangClause from "./changClause";
import rule from "@/lib/data-service/flight/newFligthApi/buyer_dom_white_rule";

export default {
  name: "tableFligth",
  data() {
    return {
      ruleText: "",
      detailList: {},
      selectList: [],
      indexShow: null,
      activeBtn: {}
    };
  },
  components: { ChangClause },
  props: {
    getChildAirInfo: {
      type: Function,
      default: () => {
        return "start";
      }
    },
    flights: {
      type: Array,
      default: []
    },
    arrIndex: Number
  },
  watch: {
    activeBtn: {
      handler: function(newV, oldV) {
        this.$emit("activeBtn", newV);
      },
      deep: true
    }
  },
  methods: {
    //退改规则
    backRule(val) {
      this.ruleText = "";
      let data = {
        arrCityPortCode: val.arrAirportCode,
        cabinCode: val.policys[0].cabin.chdCabinCode,
        depCityPortCode: val.depAirportCode,
        depDate: val.depDate,
        flightNo: val.flightNo,
        flightType: this.flights.length > 1 ? 2 : 1,
        psgType: 2,
        supplierType: val.supplierType,
        policyId: val.policys[0].policyId,
        extData: val.policys[0].extData,
      };
      rule(data).then(res => {
        let refRule = res.refRule.rules;
        let chaRule = res.chaRule.rules;
        refRule.map(item => (item.type = "refRule"));
        chaRule.map(item => (item.type = "chaRule"));
        this.ruleText = [refRule, chaRule];
        // this.ruleText = res.chaRule.content;
      });
    },

    selectBtn(val, index) {
      if (val.btnShow) {
        val.btnShow = false;
        this.flights.map(item => {
          item.btnStop = false;
        });
      } else {
        val.btnShow = true;
        this.flights.map(item => {
          item.btnStop = true;
        });
      }

      this.activeBtn.show = val.btnShow;
      this.activeBtn.active = this.arrIndex + "," + index;
      this.$forceUpdate();
    }
  }
};
