//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tableFligth from "./table-fligth";
import childrenChangeFlight from "@/lib/data-service/flight/newFligthApi/buyer_dom_manage_childrenChangeFlight";

export default {
  name: "childTicket",
  data() {
    return {
      tableShow: "",
      flights: [],
      arrIndex: null
    };
  },
  props: {
    detailData: Object,
    getChildAirInfo: {
      type: Function,
      default: () => {
        return "start";
      }
    }
  },
  components: { tableFligth },
  methods: {
    activeBtn(val) {
      console.log("接收", val);
    },
    getAir(val) {
      let data = {
        // orderNo: this.$route.query.ordersNo,
        aduTicketNumber: val.aduTicketNumber
      };
      childrenChangeFlight(data).then(res => {
        this.flights.map(item => {
          item.btnShow ? (item.btnShow = true) : (item.btnShow = false);
          item.btnStop = false;
        });
        this.flights = res.flights;
      });
    }
  }
};
