//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import upLoadImg from "@/lib/data-service/default/web_common_upload_aptitudeSingleImage";
import ChangClause from "@/page/distributor/air-ticket/components/changClause";
import rule from "@/lib/data-service/flight/newFligthApi/buyer_dom_white_rule";
import ChildTicket from "@/page/distributor/air-ticket/components/childTicket";
//import index from "../admin/change-order-list";
// 国内航班列表查询
const flights = require(`@/lib/data-service/flight/flight_domestic_flights`);
import buyer_dom_manage_changeSearch from '@/lib/data-service/flight/buyer_dom_manage_changeSearch'
import buyer_dom_manage_changeQuery from '@/lib/data-service/flight/buyer_dom_manage_changeQuery'
import CalendarBox from "@/page/distributor/air-ticket/components/calendarChangeFlight";
import InlandFlightList from "@/page/distributor/air-ticket/components/inlandFlightList/1.0.1/inlandFlightList";
import luggage from "@/lib/data-service/flight/buyer_dom_white_luggage";

export default {
  name: "ChangRefund",
  data() {
    return {
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 1000 * 3600 * 24;
        }
      },
      defaultText: "-----",
      ruleText: "",
      options: [
        {
          value: 1,
          label: "自愿改签"
        },
        {
          value: 2,
          label: "非自愿改签"
        }
      ],
      radio: "",
      textarea: "",
      formData: {
        changeType: 1,
        orderNo: this.detailData.orderNo,
        passengerNoList: [],
        remark: "",
        segmentList: [],
        chaReason: 1
      },
      segmentList: [],
      changeSegment: [],
      disabledTime: null,
      flight: {},
      fileImg: [],

      dialogChangeVisible: false,
      flightStartSort: true,
      flightEndSort: true,
      changeParams: {
        flightType: 1,
        segmentTypeText: '',
        needPnr: 0,
        depCityPortName: '',
        // 出发城市三字码。
        depCityPortCode: '',
        arrCityPortName: '',
        // 到达城市三字码。
        arrCityPortCode: '',
        // 出发日期
        depDate: '',
        // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
        cabinRank: 0,
        airLineName: '',
        // 航空公司二字码
        airLineCode: '',
        // psgType
        psgType: '',
        //  是否直飞
        directFlight: 0,
        // 折扣
        discount: 0,
        takeOffInterval: ''
      },
      changingIndex: null,
      // pickerOptions: this.setEndTime1(),
      saveWeek: "",
      initStatues: 0,
      loading: false,
      flightsList: [],
      luggage: '',
      changeRuleText: [],
      psgType: 1, //  乘机人类型
      creditState: false,
      reserveType: '确定', // 按钮状态
    };
  },
  components: {
    ChangClause,
    ChildTicket,
    CalendarBox,
    InlandFlightList
  },
  props: ["detailData"],
  watch: {
    formData: {
      deep: true,
      handler: function(newV, oldV) {
        this.$emit("getData", newV, this.segmentList);
      }
    }
  },
  methods: {
    setEndTime1() {
      return {
        disabledDate: time => {
          return time.getTime() < Date.now() - 1000 * 3600 * 24;
        }
      };
    },
    checkSelectable(val) {
      return true;
    },
    uploadImg(file) {
      upLoadImg(file.file).then(res => {
        this.fileImg.push({ url: res.fileList[0], uid: file.file.uid });
        this.getUrl(this.fileImg);
      });
    },
    getChildAirInfo(data) {
      let passengerNoList = this.formData.passengerNoList;

      let segmenList = {
        cabinCode: data.val.policys[0].cabin.chdCabinCode,
        depDate: data.val.depDate,
        depTime: data.val.depTime,
        flightNo: data.val.flightNo,
        segmentNo: data.changAir.segmentNo,
        arrDate: data.val.arrDate,
        arrTime: data.val.arrTime
      };
      if (this.formData.passengerNoList.length == 0) {
        passengerNoList.push(data.air.psgNo);
      } else {
        if (!data.btnShow) {
          passengerNoList.push(data.air.psgNo);
        } else {
          this.formData.passengerNoList.map((item, index) => {
            if (item == data.air.psgNo) {
              this.formData.passengerNoList.splice(index, 1);
            }
          });
          this.formData.segmentList.map((segment, index) => {
            if (segment.segmentNo == data.changAir.segmentNo) {
              this.formData.segmentList.splice(index, 1);
            }
          });
          console.log(this.formData);
          return;
        }
      }

      this.formData.segmentList.push(segmenList);
      console.log(this.formData);
    },
    backRule(val) {
      console.log(val);
      this.ruleText = "";
      let data = {
        arrCityPortCode: val.arrAirportCode,
        cabinCode:
                this.psgType == 2
                        ? val.policys[0].cabin.chdCabinCode
                        : val.policys[0].cabin.cabinCode,
        depCityPortCode: val.depAirportCode,
        depDate: val.depDate,
        flightNo: val.flightNo,
        flightType: this.airType,
        psgType: this.psgType,
        supplierType: val.supplierType,
        policyId: val.policys[0].policyId,
        extData: val.policys[0].extData,
      };
      rule(data).then(res => {
        // let refRule = res.refRule.rules;
        // let chaRule = res.chaRule.rules;
        // refRule.map(item => (item.type = "refRule"));
        // chaRule.map(item => (item.type = "chaRule"));
        // this.ruleText = [refRule, chaRule];
        this.ruleText = res.chaRule.content;
      });
    },
    backLuggage(val, policys) {
      this.luggage = ''
      this.ruleObj = val;
      let data = {
        arrCityPortCode: val.arrAirportCode,
        cabinCode:
                this.psgType == 2
                        ? policys.cabin.chdCabinCode
                        : policys.cabin.cabinCode,
        depCityPortCode: val.depAirportCode,
        depDate: val.depDate,
        flightNo: val.flightNo,
        flightType: this.airType,
        psgType: this.psgType,
        supplierType: policys.supplierType || 1,
        policyId: policys.policyId,
        extData: policys.extData,
        businessExt: policys.businessExt,
      };
      this.getLuggage(data);
    },
    getLuggage(data) {
      luggage(data).then(res => {
        this.luggage = res.luggage;
      });
    },
    getUrl(val) {
      let url = val.map(item => {
        return item.url;
      });
      this.formData.url = url.join(",");
    },
    detectionImg(file) {
      var reg2 = /^(\s|\S)+(jpg|png|bmp)+$/;
      const isLt2M = file.size / 1024 < 500;
      if (!reg2.test(file.name)) {
        this.$message({ type: "warning", message: "图片格式上传错误！" });
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500K!");
        return false;
      }
    },
    removeImg(file) {
      this.fileImg.map((item, index) => {
        item.uid === file.uid ? this.fileImg.splice(index, 1) : "";
      });
      this.getUrl(this.fileImg);
    },
    selectType(val) {
      this.disabledTime = val;
      this.$forceUpdate();
    },

    selectFlight(val, name, flight) {
      this.segmentList[name] = JSON.parse(JSON.stringify(val));
      let list = [].concat(...this.segmentList);
      list = list.filter(item => item);
      //console.log(list);
      let arr = [];
      /**去重 */
      var setFlight = list => {
        for (let i = 0; i < list.length - 1; i++) {
          if (list.length > 1) {
            for (let j = 1; j < list.length; j++) {
              if (list[j].segmentType === list[i].segmentType) {
                list.splice(j, 1);
              }
            }
          }
        }
      };
      console.log(list)
      this.changeSegment = JSON.parse(JSON.stringify(list));
      setFlight(this.changeSegment);
      this.formData.segmentList = this.changeSegment;
      this.selectPassenger(val, name, flight);
    },
    selectPassenger(val, name, flight) {
      val.length > 0 ? (this.flight[name] = flight) : (this.flight[name] = []);
      let list = Object.values(this.flight);
      let psgNo = list.map(item => {
        return item.psgNo;
      });
      this.formData.passengerNoList = psgNo;
    },
    // 改签选择航班
    flightTimeSort(type) {
      if (this.flightsList.length === 0) return;
      if (type === "start") {
        this.flightStartSort = !this.flightStartSort;
        this.flightEndSort = !this.flightEndSort;
        // depTime
        this.timeSort(this.flightsList, "depTime", this.flightStartSort);
      } else {
        this.flightEndSort = !this.flightEndSort;
        this.flightStartSort = !this.flightStartSort;
        // arrTime
        this.timeSort(this.flightsList, "arrTime", this.flightEndSort);
      }
    },
    timeSort(list, name, arrangeType) {
      if (arrangeType) {
        list.sort((a, b) => {
          let one = moment(this.changeParams).format("YYYY-MM-DD") + " " + a[name];
          let two = moment(this.time1).format("YYYY-MM-DD") + " " + b[name];
          return moment(one).valueOf() - moment(two).valueOf();
        });
      } else {
        list.sort((a, b) => {
          let one = moment(this.changeParams.depDate).format("YYYY-MM-DD") + " " + a[name];
          let two = moment(this.changeParams.depDate).format("YYYY-MM-DD") + " " + b[name];
          return moment(two).valueOf() - moment(one).valueOf();
        });
      }
    },
    clickDate(val) {
      this.$set(this.changeParams, 'depDate', val)
      this.saveWeek = val;
      this.initStatues = 0;
      this.searchChangeFlight();
    },
    // 选择航班
    changeFlight(val, index) {
      if (!val.beginDate || val.beginDate === '') {
        this.$message({
          type: "warning",
          message: '请先选择起飞时间'
        })
        return
      }
      let copyShareAirlineCode = val.shareAirlineCode
      let copyFlightNo = val.flightNo
      this.changingIndex = index
      let params = {
        // 航程类型
        flightType: 1,
        segmentTypeText: val.segmentTypeText,
        needPnr: 0,
        depCityPortName: val.sCityName,
        // 出发城市三字码。
        depCityPortCode: val.sAirportCode,
        arrCityPortName: val.eCityName,
        // 到达城市三字码。
        arrCityPortCode: val.eAirportCode,
        // 出发日期
        depDate: this.$moment(val.startTime).format('YYYY-MM-DD'),
        // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱。
        cabinRank: 0,
        airLineName: val.airlineName,
        // 航空公司二字码
        airLineCode: copyShareAirlineCode ? copyShareAirlineCode.slice(0, 2) : copyFlightNo.slice(0, 2),
        // psgType
        psgType: val.psgType,
        //  是否直飞
        directFlight: 0,
        // 折扣
        discount: 0,
        takeOffInterval: '',
        supplierType: this.detailData.supplierType,
        flightNo: val.oriShareFlightNo ? val.oriShareFlightNo : val.oriFlightNo,
        orderNo: this.detailData.orderNo
      };
      this.changeParams = Object.assign({}, params)
      this.dialogChangeVisible = true
      this.saveWeek = val.beginDate
      //this.initStatues = 0
      this.searchChangeFlight()
    },
    searchChangeFlight() {
      if (!this.changeParams.depDate) {
        this.$message({
          message: '请先选择日期',
          type: 'warning'
        });
        return
      }
      this.saveWeek = this.changeParams.depDate
      this.initStatues++
      this.loading = true
      buyer_dom_manage_changeQuery(this.changeParams).then(data => {
        if (data.flights) {
          this.loading = false;
          let flightsList = data.flights
          flightsList.forEach((value, index) => {
            let rebatePrice = 0
            let settlementPriceTaxes = 0
            value.policys.forEach((value1, index1) => {
              if (index1 === 0) {
                rebatePrice = value1.priceData[0].rebatePrice
                settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
              } else {
                if (rebatePrice > value1.priceData[0].rebatePrice) {
                  rebatePrice = value1.priceData[0].rebatePrice
                }
                if (settlementPriceTaxes > value1.priceData[0].settlementPriceTaxes) {
                  settlementPriceTaxes = value1.priceData[0].settlementPriceTaxes
                }
              }
            })
            value.rebatePrice = rebatePrice
            value.settlementPriceTaxes = settlementPriceTaxes
          })
          this.flightsList = flightsList;
          this.creditState = data.creditState;
          this.airTypeShow = true;
        } else {
          this.loading = false;
        }
      }, error => {
        this.flightsList = []
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      });
    },
    // 行程选择
    journeyType(data, policy) {
      console.log('5', data, policy, 'this.changeSegment', this.changeSegment)
      let changeSegmentItem = this.changeSegment[this.changingIndex]
      changeSegmentItem.flightNo = data.hasShare ? data.sharedFlightNo : data.flightNo
      changeSegmentItem.cabinCode = this.psgType == 2 ? policy.cabin.chdCabinCode : policy.cabin.cabinCode
      changeSegmentItem.oriStartTime = `${data.depDate} ${data.depTime}`
      changeSegmentItem.startTime = `${data.depDate} ${data.depTime}`
      changeSegmentItem.endTime = `${data.arrDate} ${data.arrTime}`
      changeSegmentItem.price = policy.priceData[0].price

      changeSegmentItem.newArrAirportCode = data.arrAirportCode
      changeSegmentItem.newArrAirportName = data.arrAirportName
      changeSegmentItem.newDepAirportCode = data.depAirportCode
      changeSegmentItem.newDepAirportName = data.depAirportName
      this.changeSegment[this.changingIndex] = changeSegmentItem
      console.log('changeSegment', this.changeSegment)
      this.dialogChangeVisible = false
      this.$message({
        message: '选择成功',
        type: 'success'
      });
    },

    clickTimeChangeFlight(item, index, value) {
      if (value === null) {
        return
      }
      this.changeFlight(item, index)
    },
    closeChangeDialog() {
      this.dialogChangeVisible = false
      let changeSegmentItem = this.changeSegment[this.changingIndex]
      changeSegmentItem.startTime = changeSegmentItem.oriStartDate
      this.changeSegment[this.changingIndex] = changeSegmentItem
    },
    reactFormData() {
      this.formData = {
        changeType: 1,
        orderNo: this.detailData.orderNo,
        passengerNoList: [],
        remark: "",
        segmentList: [],
        chaReason: 1
      }
    },
  }
};
