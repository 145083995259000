// buyer_dom_manage_newApi_changeQuery
const __request = require(`./__request/flight-admin/__request_contentType_json`);
const buyer_dom_manage_newApi_changeQuery = (pParameter) => {
    if (!pParameter) pParameter = {};
    let data = {
        method: 'post',
        urlSuffix:'/buyer/dom/manage/newApi/changeQuery',
        data: pParameter,
        // enable_error_alert: false // 临时隐藏
    }
    return __request(data)
}
module.exports = buyer_dom_manage_newApi_changeQuery;
