import ChangClause from "@/page/distributor/air-ticket/components/changClause";
import LuggageClause from '@/page/distributor/air-ticket/components/luggageClause/index.vue'
export default {
    data() {
        return {
            //ruleShow: 0,
            showListNum: 3,
            moreState: false
        };
    },
    props: {
        cabins: Array,
        journeyType: Function,
        backRule: Function,
        backLuggage: Function,
        ruleText: Array,
        loading: Boolean,
        flightsShow: Boolean,
        psgType: Number,
        hasChd: Boolean
    },
    watch: {
        loading(val) {
            this.reset();
        },
        flightsShow(val) {
            this.reset();
        }
    },
    components: { ChangClause, LuggageClause },
    methods: {
        reset() {
            this.showListNum = 3;
            this.moreState = false;
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        showList() {
            this.moreState = !this.moreState;
            this.moreState
                ? (this.showListNum = this.cabins.length)
                : (this.showListNum = 3);
        }
    },
    created() {}
};