const __request = require(`./__request/__request_contentType_json`);

// 改期航班查询
// 接口文档地址：http://192.168.0.33:8985/swagger-ui.html#/buyer-controller/changeSearchUsingPOST
const buyer_dom_manage_changeSearch = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/buyer/dom/manage/changeSearch",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_manage_changeSearch;