// buyer_dom_white_change
const __request = require(`./__request/flight-admin/__request_contentType_json`);
const buyer_dom_white_change = (pParameter) => {
    if (!pParameter) pParameter = {};
    let data = {
        method: 'post',
        urlSuffix:'/buyer/dom/white/change',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    }
    return __request(data)
}
module.exports = buyer_dom_white_change;
