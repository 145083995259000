//
//
//
//
//
//
//
//
//

export default {
  name: "component_name",
  data() {
    return {
      imageUrl: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`
    };
  },
  props: {
    logoImg: ""
  },
  methods: {}
};
