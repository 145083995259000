// buyer_dom_manage_changeReasonEnum
const __request = require(`./__request/flight-admin/__request_contentType_json`);
const buyer_dom_manage_changeReasonEnum = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/buyer/dom/manage/changeReasonEnum",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_manage_changeReasonEnum;
