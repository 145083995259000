import { render, staticRenderFns } from "./airImgLogo.vue?vue&type=template&id=1f8d7143&scoped=true&"
import script from "./airImgLogo.vue?vue&type=script&lang=js&"
export * from "./airImgLogo.vue?vue&type=script&lang=js&"
import style0 from "./airImgLogo.vue?vue&type=style&index=0&id=1f8d7143&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f8d7143",
  null
  
)

export default component.exports