// buyer_dom_manage_changeQuery
const __request = require(`./__request/__request_contentType_json`);

// 改期航班查询(新版)
// 接口文档地址：http://192.168.0.31:8985/doc.html#/yinzhilv-flight/buyer-controller/changeQueryUsingPOST
const buyer_dom_manage_changeQuery = (pParameter) => {
    if (!pParameter) pParameter = {};
    pParameter = {
        method: 'post',
        urlSuffix:"/buyer/dom/manage/changeQuery",
        data: pParameter
    }

    return __request(pParameter)
}
module.exports = buyer_dom_manage_changeQuery;