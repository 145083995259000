const __request = require(`../__request/flight-admin/__request_contentType_json`);

// 国内改签申请详情
export default (data) => {
let pParameter = {
    method: 'post',
    urlSuffix: '/buyer/dom/manage/change',
    data:data,
    enable_error_alert: false // 临时隐藏
  }

  return __request(pParameter)
}

