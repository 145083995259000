import AirStop from "@/page/distributor/air-ticket/components/airStop";
import SortBox from "@/page/distributor/air-ticket/components/sortBox";
import FlightItem from "@/page/distributor/air-ticket/components/inlandFlightItem/1.0.0/index.vue";
import ImgLogo from "@/page/distributor/air-ticket/components/airImgLogo";
export default {
    data() {
        return {
            arrowsShow: null,
            flightsShow: [],
            checkedTax: false,
            flightsIndex: null,
            settlementPrice: true,
            sortShow: [true, false, false],
            imageUrl: `https://lyn.oss-cn-shenzhen.aliyuncs.com/temp/airline-blank-map.jpg`
        };
    },
    components: { AirStop, SortBox, FlightItem, ImgLogo },
    props: {
        flightTimeSort: {
            type: Function,
            default: function() {}
        },
        journeyType: {
            type: Function,
            default: function() {}
        },
        backRule: {
            type: Function,
            default: function() {}
        },
        backLuggage: {
            type: Function,
            default: function() {}
        },
        flightsList: {
            type: Array,
            default: []
        },
        loading: {
            type: Boolean,
            default: false
        },

        flightSartSort: {
            type: Boolean,
            default: false
        },
        flightEndSort: {
            type: Boolean,
            default: false
        },
        ruleText: {
            type: Array,
            default: []
        },
        dialogLoading: {
            type: Boolean,
            default: false
        },
        psgType: {
            type: Number,
            default: 1
        },
        hasChd: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        loading(val) {
            this.flightsShow.length = 0;
        },
        flightsList(val) {
            val.map((item, index) => {
                this.flightsShow[index] = false;
            });
        }
    },
    computed: {},
    methods: {
        handleSort(val, index) {
            this.flightTimeSort(val)
            this.setSortActive(index)
        },
        setSortActive(index) {
            if (this.flightsList.length === 0) return;
            if (!this.sortShow[index]) {
                this.sortShow.forEach((item, index) => {
                    this.$set(this.sortShow, index, false);
                });
                this.sortShow[index] = true;
            }
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        setSettlementPrice() {
            if (this.flightsList.length === 0) return;
            this.settlementPrice = !this.settlementPrice;
            this.flightsList.sort((a, b) => {
                if (this.settlementPrice) {
                    return (
                        a.policys[0].priceData[0].rebatePrice -
                        b.policys[0].priceData[0].rebatePrice
                    );
                } else {
                    return (
                        b.policys[0].priceData[0].rebatePrice -
                        a.policys[0].priceData[0].rebatePrice
                    );
                }
            });
        }
    }
};