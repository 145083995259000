const __request = require(`../__request/__request_contentType_json`);

// 国内退票取消退票
export default (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/buyer/dom/manage/childrenChangeFlight',
        data: data
    }

    return __request(pParameter)
}

